@import 'src/shared/styles/mixins';
@import 'src/shared/styles/variables';

.droppable-element {
	@include theme-aware('background', 'background-shade-side');
	@include theme-aware('border-color', 'background-shade-side');
	border-width: 0.125rem;
	border-style: solid;
	border-radius: 0.5rem;
	margin-bottom: 0.3rem;
	padding: 0rem 1rem;

	&:hover {
		@include theme-aware('border-color', 'card-border');
		border-width: 0.125rem;
		border-style: solid;
		cursor: grab;
	}

	&.no-drag {
		&:hover {
			cursor: pointer;
		}
	}

	.pf-l-flex > * {
		margin-right: 0rem;
	}

	.pf-c-truncate,
	.pf-c-truncate__start {
		min-width: auto;
	}
}
