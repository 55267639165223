@import '../mixins';
@import '../variables';

.form-group-col {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	gap: $space-sm;
	width: 100%;
}

button {
}

.pf-c-select,
.pf-c-select__toggle,
.pf-c-select__toggle-wrapper,
.pf-c-select__toggle-arrow,
.pf-c-select__menu,
.pf-c-select__menu-item,
.pf-c-text-input-group__text-input {
	border-radius: 0.375rem;
	@include theme-aware('color', 'text-primary');

	&::before {
		border-radius: 0.375rem;
	}
}

.pf-c-select__menu {
	max-height: 20rem;
	overflow-y: auto;
	margin: 0 !important;
}

.pf-c-text-input-group__text-input {
	.pf-c-text-input-group__icon {
		svg {
			@include theme-aware('background', 'text-primary');
		}
	}
}

input {
	&.pf-c-form-control {
		border-radius: 0.375rem;
	}
}

.pf-c-select__menu-wrapper,
.pf-c-select__menu-item {
	&:hover {
		@include theme-aware('background', 'card-hover');
	}
}

.pf-c-form__group {
	width: 100%;
}

.pf-c-form__group-label {
	.pf-c-form__label {
		.pf-c-form__label-required {
			color: red;
		}
	}
}
