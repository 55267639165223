@import '../../../shared/styles/colors';
@import '../../../shared/styles/variables';

.data-builder-drop-grid-container {
	.data-builder-drop-grid-groups {
		.data-builder-drop-grid-group {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			padding-bottom: 1rem;

			.zone-divider {
				margin: 0 1rem 0 1rem;
			}

			.data-builder-drop-grid-zone {
				width: auto !important;
			}
		}
	}

	.data-builder-drop-grid-zone {
		.vertical-items-align {
			display: flex;
			justify-content: center;
		}

		.draggable-wrapper {
			padding: 10px;
			min-height: 56px;
			display: flex;
			overflow: auto;
			gap: 0.5rem;

			.drop-indicator {
				height: 36px;
				width: 8rem;
				border: 0.125rem dashed map-get($basic-zi-colors, 'primary');
			}

			.data-builder-drop-grid-item-wrapper {
				min-height: 36px;

				&.initial-vertical-item {
					display: flex;
					align-items: center;
					background-color: map-get($basic-zi-colors, 'accent-dark');
					padding: 0.25rem;
				}

				&.secondary-vertical-item {
					padding: 0.25rem;
				}

				&.vertical-item-active {
					border: 0.125rem solid map-get($basic-zi-colors, 'primary');
				}

				.data-builder-drop-grid-item {
					position: relative;

					.drag-handle {
						width: 20px;
						height: 20px;
						cursor: grab;
						margin-right: 8px;
						position: absolute;
						z-index: 1500;
						left: -8px;
						top: -8px;
						display: none;
					}

					&:hover {
						.drag-handle {
							display: block;
						}
					}
				}
			}
		}
	}

	.pf-c-form {
		width: 100%;
	}
	.pf-c-form__group-label {
		flex-basis: 10%;
	}
	.pf-c-form__group-control {
		flex-basis: 90%;
	}

	.data-builder-drop-grid-zone {
		display: flex;
		gap: 3rem;
		align-items: center;
	}

	.flex-column {
		flex-direction: column;
	}

	.flex-wrap {
		flex-wrap: wrap;
	}
}

.join-icon {
	padding-top: 10px;
	cursor: pointer;
	margin-left: 15px;
	margin-right: 15px;
}

.lbl-jointype {
	width: 200px;
	position: relative;
	left: 38%;
	border: solid 1px black;
	padding: 15px;
}

.km-block {
	border: solid 1px #e7e7e7;
	padding: 6px;
	border-bottom: solid 1px black;
	position: relative;
	bottom: 12px;
}

.mr-10 {
	margin-right: 10px;
}

.valid-join {
	padding: 9.5px;
}
