@import '../../../shared/styles/colors';
@import '../../../shared/styles/variables';

$border-radius: 0.25rem;

.data-builder-grid-item-container {
	width: 100%;
	.data-builder-grid-item-dropdown {
		width: 100%;
		button {
			width: 100%;
		}
	}
	.data-builder-grid-item-button {
		display: flex;
		justify-content: space-between;
		width: 100% !important;
		align-items: center;
		background-color: map-get($basic-zi-colors, 'light');
		border: 0.0625rem solid map-get($basic-zi-colors, 'accent-light');
		width: fit-content;
		height: 2.25rem;

		&.invalid {
			border: 0.125rem solid map-get($basic-zi-colors, 'error-red-border');
			color: map-get($basic-zi-colors, 'error-red-text');
			button {
				color: map-get($basic-zi-colors, 'error-red-text');
			}
		}

		.label {
			margin: 0.25rem;
			font-weight: bold;
			padding: 0.25rem;
			border-radius: 0.25rem;
			height: 1.875rem;
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
			max-width: 8.4rem;
			&:hover {
				cursor: pointer;
				background-color: map-get($basic-zi-colors, 'accent-dark');
			}
		}
	}

	.date-selector-container {
		padding: 0.25rem;
		background-color: map-get($basic-zi-colors, 'light');
		border: 0.0625rem solid map-get($basic-zi-colors, 'accent-light');
		display: flex;
		justify-content: center;
		align-items: center;
		height: 2.25rem;

		.btn-dateperiod {
			&.pf-c-button {
				border: unset !important;
				height: 1.875rem;
				border-radius: 0.25rem !important;
				margin: 0.3125rem 0 0.3125rem 0 !important;
				padding: 0 0.3125rem 0 0.3125rem !important;

				&:hover {
					background-color: map-get($basic-zi-colors, 'accent-dark') !important;
				}
			}
		}
	}

	.drilldown-item {
		display: flex;
		justify-content: space-between;
		align-items: center;

		span:first-child {
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 0.5rem;
		}
	}
}
