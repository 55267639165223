.tutorial-type {
	margin-bottom: 0rem !important;
	font-weight: bold;
	text-transform: uppercase;
	font-size: 0.6rem;
}

.tutorial-title {
	font-size: 0.75rem;
}
