@import 'src/shared/styles/mixins';
@import 'src/shared/styles/variables';

.zi-notification-count {
	position: absolute;
	top: -0.85rem;
	left: 0.85rem;
	font-size: 0.75rem;
	border-radius: 100%;
	width: 1.25rem;
	height: 1.25rem;
	max-height: 1.25rem;
	max-width: 1.25rem;
	text-align: center;
	line-height: 1.25rem;
	@include theme-aware('background', 'brand-primary');
	@include theme-aware('color', 'text-secondary');
}

@keyframes notificationAnimation {
	0% {
		animation-timing-function: ease-in;
		opacity: 1;
		transform: translateY(0px);
	}

	24% {
		opacity: 1;
	}

	40% {
		animation-timing-function: ease-in;
		transform: translateY(-12px);
	}

	65% {
		animation-timing-function: ease-in;
		transform: translateY(-6px);
	}

	82% {
		animation-timing-function: ease-in;
		transform: translateY(-3px);
	}

	93% {
		animation-timing-function: ease-in;
		transform: translateY(-2px);
	}

	25%,
	55%,
	75%,
	87% {
		animation-timing-function: ease-out;
		transform: translateY(0px);
	}

	100% {
		animation-timing-function: ease-out;
		opacity: 1;
		transform: translateY(0px);
	}
}

.bounce-animation {
	animation: notificationAnimation 1s ease-in 0s 1 normal forwards;
}
