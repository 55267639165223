@import 'src/shared/styles/mixins';
@import 'src/shared/styles/variables';

.component-container {
	.present-options-list {
		margin-top: $space-sm;

		.present-option {
			align-items: center;

			.present-option-icon {
				width: 8rem;
				height: 8rem;
				border: 1px solid black;
				@include theme-aware('border-color', 'card-border');
				border-radius: 0.5rem;

				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;

				@include standard-icon;
			}
		}
	}
}
