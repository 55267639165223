@import 'src/shared/styles/mixins';
@import 'src/shared/styles/variables';

.add-new-dropdown {
	max-width: min-content;
	align-self: end;
}

.dv-dropdown-container {
	display: flex;
	flex-direction: column;
}

.dv-dropdown-container.push-left {
	padding-right: 20px !important;
}
