@import 'src/shared/styles/mixins';
@import 'src/shared/styles/variables';

.back-btn {
	@include standard-icon;
	width: 100%;
	display: flex;
	justify-content: flex-start;
	margin-bottom: $space-sm;

	.pf-c-button {
		padding: 0;
		margin: 0;
	}
}
