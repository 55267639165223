@import 'src/shared/styles/mixins';
@import 'src/shared/styles/variables';

.action-card-container {
	margin: 0px !important;
	> .action-card:hover {
		@include theme-aware('background', 'card-hover');
	}
	.action-card {
		//BOX SHADOW
		box-shadow: 0 1px 1px 0 darken(map-get($basic-zi-colors, 'accent-dark'), 40%),
			0 1px 1px 0 darken(map-get($basic-zi-colors, 'accent-dark'), 40%);

		font-size: $text-sm;
		border-radius: 0.375rem;
		display: flex;
		flex-direction: column;
		justify-content: center;
		@include theme-aware('background', 'card-background');
		@include theme-aware('color', 'text-primary');
		// @include theme-aware('border-color', 'card-border');
		// border-style: solid;
		// border-width: 0.3rem;
		margin: 0.2rem;
		height: 4rem;
		border-radius: $border-radius-all;

		.action-card-icon {
			margin: 0px 0.5rem !important;
		}
	}
	:hover {
		cursor: pointer;
	}
}
