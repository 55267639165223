.zi-inputgroup > .pf-c-select {
	width: 25%;
}

.zi-drag-grip {
	align-self: center;
	height: 1em;
	width: 1em;
	margin-right: 0.25em;
}
