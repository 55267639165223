@import '../mixins';
@import '../variables';
.pf-c-modal-box {
	border-radius: 0.375rem;
	@include theme-aware('color', 'text-primary');
	@include theme-aware('background', 'card-background');

	svg {
		@include theme-aware('color', 'text-primary');
	}
}

.pf-c-modal-box__footer {
	display: flex;
	justify-content: flex-end;
}
