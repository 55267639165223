@import '../../../shared/styles/colors';
@import '../../../shared/styles/variables';

.dataframe-container {
	height: 100%;
	.pf-l-flex {
		align-items: center;

		&.row-spacing {
			margin: 0 0 1rem 0;
		}
	}

	.save-btn {
		margin-right: 1rem;
	}
}

.pf-c-dual-list-selector {
	padding-top: 1rem;
}

.and-header {
	padding: 1rem 0 1rem 0;
}

.value-section {
	padding-top: 1rem;
}

.data-builder-container > .pf-c-modal-box__body {
	overflow: visible;
}
