@import 'src/shared/styles/mixins';
@import 'src/shared/styles/variables';

.report-summary {
	h2 {
		font-size: 2.5rem;
	}

	.right-title {
		font-size: $text-md;
	}

	.report-summary-item {
		//BOX SHADOW
		box-shadow: 0 1px 1px 0 darken(map-get($basic-zi-colors, 'accent-dark'), 40%),
			0 1px 1px 0 darken(map-get($basic-zi-colors, 'accent-dark'), 40%);

		max-width: 8rem;
		background: rgba(245, 245, 245, 0.65);
		color: black;
		padding: 1rem;
		border-radius: $border-radius-all;
		margin-top: 0.2rem;
	}

	.report-summary-number {
		font-size: $text-xxl;
	}

	.report-summary-text {
		font-size: $text-sm;
	}

	// .pf-l-flex.pf-m-column > * {
	// 	margin: 0px;
	// }
}

.report-snapshot {
	.report-container {
		width: 100%;

		.right-title {
			text-transform: capitalize;
		}

		.line-container {
			margin: 0.2rem 0;
			.loader {
				.pf-c-spinner {
					--pf-c-spinner--Color: #f5f5f5;
				}
			}

			a {
				color: black;
				&:hover {
					color: black;
					text-decoration: none;
				}

				.zi-chart-container {
					padding: unset;

					.highcharts-container {
						border-radius: $border-radius-all;
					}

					.pf-c-empty-state {
						border-radius: $border-radius-all;
						padding: 0.5rem;

						.pf-c-empty-state__body,
						.pf-c-empty-state__primary {
							display: none;
						}
						.pf-c-empty-state__content {
							.fa-circle-info {
								font-size: 5.5em;
							}
						}
					}
				}
			}

			.no-report-data {
				box-shadow: 0 1px 1px 0 darken(map-get($basic-zi-colors, 'accent-dark'), 40%),
					0 1px 1px 0 darken(map-get($basic-zi-colors, 'accent-dark'), 40%);
				@include theme-aware('background', 'card-background');
				@include theme-aware('color', 'text-primary');
				padding: 1rem;
				border-radius: $border-radius-all;
				display: flex;
				align-items: center;
				gap: 0.5rem;

				.fa-triangle-exclamation {
					color: map-get($zi-brand-colors, 'orange-yello');
				}

				a {
					color: map-get($basic-zi-colors, 'primary');
					&:hover {
						color: darken(map-get($basic-zi-colors, 'primary'), 5%);
					}
				}
			}
		}
	}
}

.report-chart-text {
	height: 100%;
	font-size: $text-sm;
}
