@import 'src/shared/styles/variables';
@import 'src/shared/styles/mixins';

.date-button {
	margin: 0 0.313rem;
	margin-bottom: 1.5rem;
	> button {
		width: 180px;
		height: 60px;
		@include theme-aware('color', 'brand-primary');
		@include theme-aware('border-color', 'brand-primary');
		border: solid 0.063rem;
	}

	&.selected {
		> button {
			@include theme-aware('color', 'badge-text');
			@include theme-aware('border-color', 'brand-primary');
			@include theme-aware('background-color', 'brand-primary');
		}
	}
}
