.pf-c-droppable:empty {
	height: 100%;
}

.pf-c-dual-list-selector__menu {
	background-color: var(--pf-global--BackgroundColor--100);
}

.pf-c-form-control {
	--pf-c-form-control--placeholder--Color: var(--pf-global--Color--400);
}
