@import 'themes';
@import 'variables';

/**
 * theme-aware - Change color of a css property based on the currently active theme
 *
 * @param  {css-property}     CSS property
 * @param  {theme-item}   Color name defined in the themes under _themes.scss
 *
 * @example - @include theme-aware('color', 'text-primary');
 * @returns - color: #000;
 */
@mixin theme-aware($css-property, $theme-item) {
	@each $theme-name, $theme-color in $themes {
		.theme-#{$theme-name} & {
			#{$css-property}: map-get(map-get($themes, $theme-name), $theme-item);
		}
	}
}

@mixin theme-aware-variables($pf-color, $theme-item) {
	@each $theme-name, $theme-color in $themes {
		#{$pf-color}: map-get(map-get($themes, $theme-name), $theme-item);
	}
}

@mixin standard-icon() {
	.svg-inline--fa {
		min-height: $font-icon-size;
		min-width: $font-icon-size;
		@include theme-aware('color', 'text-primary');
	}
}

@mixin standard-icon-secondary() {
	.svg-inline--fa {
		min-height: $font-icon-size;
		min-width: $font-icon-size;
		@include theme-aware('color', 'text-secondary');
	}
}
