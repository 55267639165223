@import 'src/shared/styles/variables';
@import 'src/shared/styles/mixins';

.zi-chart-container {
	min-width: 18.75rem;

	.chart-controls {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		align-items: center;
		gap: 0;

		button {
			.svg-inline--fa {
				@include theme-aware('color', 'text-secondary');
			}
		}

		.pf-c-button,
		.pf-m-primary {
			.svg-inline--fa {
				width: 1rem;
				height: 1rem;
			}
		}
	}

	padding: $space-md 0;
}

.chart-type-container {
	button {
		width: 48px;
	}
}

.dv-clustered > svg {
	position: relative;
	right: 0.438rem;
}

/* Specificity increased to override PatternFly styles */
.pf-c-button.pf-m-primary.btn-chart-selected {
	@include theme-aware('color', 'brand-primary');
	@include theme-aware('background', 'text-secondary');
	@include theme-aware('border-color', 'brand-primary');
	border: solid 0.063rem;
}

.pf-c-button.pf-m-primary.btn-yaxis-selected {
	@include theme-aware('color', 'brand-primary');
	@include theme-aware('background', 'text-secondary');
	@include theme-aware('border-color', 'brand-primary');
	border: solid 0.063rem;

	& svg {
		@include theme-aware('color', 'brand-primary');
	}
}

.active-color-preview {
	width: 20px;
	height: 20px;
	display: inline-block;
}

.active-color-label {
	position: relative;
	bottom: 5px;
}
