@import 'src/shared/styles/mixins';
@import 'src/shared/styles/variables';

.component-container {
	.dashboard-options-list {
		margin-top: $space-sm;

		.dashboard-option {
			align-items: center;

			.dashboard-option-icon {
				width: 8rem;
				height: 8rem;
				border: 1px solid black;
				@include theme-aware('border-color', 'card-border');
				border-radius: 0.5rem;

				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;

				@include standard-icon;
			}
		}
	}

	.folder-container {
		&.pf-c-form__group {
			.pf-c-form__group-control {
				width: 100%;
			}
			.pf-c-form__group-label {
				padding: 0;
				@include standard-icon;
			}
		}

		.pf-c-select {
			margin-bottom: 0;
		}
		margin-top: 1rem;
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: $space-sm;

		.pf-c-select__menu {
			padding: 0rem;

			.pf-c-tree-view {
				padding: 0rem;

				.pf-c-tree-view__node {
					padding-left: 0.6rem;
					padding-top: 0.375rem;
					padding-bottom: 0.375rem;
				}
			}
		}
	}
}
