@import '../mixins';

.required {
	color: red;
}

.pf-c-page__main-section {
	.text-secondary {
		@include theme-aware('color', 'text-secondary');
	}
}
.pf-c-title {
	@include theme-aware('color', 'text-primary');
}

h1 {
	@include theme-aware('color', 'text-primary');
}

small {
	@include theme-aware('color', 'text-primary-small');
}

.d-flex {
	display: flex;
}

.align-items-center {
	align-items: center;
}
