$space-xs: 0.25rem;
$space-vsm: 6.375rem;
$space-sm: 0.5rem;
$space-md: 1rem;
$space-lg: 1.5rem;
$space-xl: 2rem;
$space-xxl: 2.5rem;
$space-xxxl: 3rem;

$text-xs: 0.75rem;
$text-smm: 0.8rem;
$text-sm: 0.875rem;
$text-md: 1rem;
$text-lg: 1.125rem;
$text-xl: 1.25rem;
$text-xll: 1.5rem;
$text-xxl: 1.75rem;

$zi-card-width: 12rem;

// should be 1.3rem do not change
$font-icon-size: 1.3rem;
$border-radius-all: 0.375rem;
