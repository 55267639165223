@import '../mixins';
@import '../variables';

.pf-c-button,
.pf-c-dropdown__toggle {
	&.pf-m-primary {
		@include theme-aware('background', 'brand-primary');
		@include theme-aware('color', 'button-primary-text');
		// @include theme-aware('border-color', 'card-border');
		border-width: 1px;
		border-style: solid;

		&:disabled {
			background: gray !important;
		}
	}

	&.pf-m-link {
		@include theme-aware('color', 'text-primary');
	}
}
ul.pf-c-dropdown__menu {
	padding-top: 0rem;
	padding-bottom: 0rem;
}

.pf-c-dropdown__menu-item {
	@include standard-icon;
	// @include theme-aware('background', 'card-background');
	@include theme-aware('color', 'text-primary');

	display: flex !important;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	gap: $space-sm;

	&:hover {
		@include theme-aware('background', 'card-background');
	}
}

.pf-c-dropdown__toggle {
	--pf-c-dropdown__toggle--PaddingTop: var(--pf-global--spacer--form-element);
	--pf-c-dropdown__toggle--PaddingRight: var(--pf-global--spacer--sm);
	--pf-c-dropdown__toggle--PaddingBottom: var(--pf-global--spacer--form-element);
	--pf-c-dropdown__toggle--PaddingLeft: var(--pf-global--spacer--sm);
	--pf-c-dropdown__toggle--FontWeight: 700;
	padding: 12px 16px 12px 24px;
}
