@import 'src/shared/styles/variables';
@import 'src/shared/styles/mixins';

.formgroup-label {
	display: flex;
	align-items: center;
	gap: 8px;
	cursor: pointer;
}

.date-selector-container {
	padding: 0.25rem;
	background-color: #fff;
	border: 0.0625rem solid #f5f5f5;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 2.25rem;

	.btn-dateperiod {
		border: unset !important;
		height: 1.875rem;
		border-radius: 0.25rem !important;
		margin: 0.3125rem 0 0.3125rem 0 !important;
		padding: 0 0.3125rem 0 0.3125rem !important;
	}
}
