@import 'src/shared/styles/variables';
@import 'src/shared/styles/mixins';

.pf-c-card__header {
	display: block;
}

.dashboard-view-container {
	.edit-dashboard {
		right: unset;
	}
	.lbl-entity-actions {
		bottom: unset;
	}
	.pf-m-align-right {
		display: flex;
	}
}

.dashboard-details-container {
	.row-container {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding-bottom: 1.5rem;

		.start-container {
			display: flex;
			flex-direction: row;
			gap: 1.5rem;
			min-width: 40%;
			align-items: flex-end;

			.folder-container {
				align-items: center;
				display: flex;
				flex-direction: row;
				gap: 0.5rem;
			}
		}
	}
}

.dashboard-form {
	.pf-c-form__group {
		display: inline-flex !important;
	}
	.pf-c-form__group-label {
		display: flex;
		align-items: center;
	}

	.filter-container {
		padding: 0.5rem;
		display: flex;
		gap: 0.5rem;
		max-width: 100%;
		width: 100%;
		flex-wrap: wrap;

		.custom-button-chip {
			display: flex;
			align-items: center;
			background-color: map-get($basic-zi-colors, 'light');
			border: 0.0625rem solid map-get($basic-zi-colors, 'accent-light');
			width: fit-content;
			height: 2.25rem;

			&.invalid {
				border: 0.125rem solid map-get($basic-zi-colors, 'error-red-border');
				color: map-get($basic-zi-colors, 'error-red-text');
				button {
					color: map-get($basic-zi-colors, 'error-red-text');
				}
			}

			.label {
				margin: 0.25rem;
				font-weight: bold;
				padding: 0.25rem;
				border-radius: 0.25rem;
				height: 1.875rem;
				&:hover {
					cursor: pointer;
					background-color: map-get($basic-zi-colors, 'accent-dark');
				}
			}
		}

		.date-selector-container {
			padding: 0.25rem;
			background-color: #fff;
			border: 0.0625rem solid #f5f5f5;
			display: flex;
			justify-content: center;
			align-items: center;
			height: 2.25rem;

			.btn-dateperiod {
				border: unset !important;
				height: 1.875rem;
				border-radius: 0.25rem !important;
				margin: 0.3125rem 0 0.3125rem 0 !important;
				padding: 0 0.3125rem 0 0.3125rem !important;
			}
		}

		.filter {
			padding: 0 0.5rem 0 0.5rem !important;
			background-color: #fff;
			border: 0.0625rem solid #f5f5f5;
			display: flex;
			justify-content: center;
			align-items: center;
			height: 2.25rem;

			&:hover {
				border-color: map-get($basic-zi-colors, 'primary');
			}

			svg {
				padding-left: 0.5rem;

				&:hover {
					cursor: pointer;
				}
			}

			.btn-filter {
				border: unset !important;
				height: 1.875rem;
				border-radius: 0.25rem !important;
				margin: 0.3125rem 0 0.3125rem 0 !important;
				padding: 0 0.3125rem 0 0.3125rem !important;
				background: white !important;
				color: black !important;
			}
		}

		.add-filter {
			padding: 0.25rem;
			background-color: #fff;
			border: 0.15rem dashed #f5f5f5;
			display: flex;
			justify-content: center;
			align-items: center;
			height: 2.25rem;

			&:hover {
				border-color: map-get($basic-zi-colors, 'primary');
			}

			.btn-add-filter {
				border: unset !important;
				height: 1.875rem;
				border-radius: 0.25rem !important;
				margin: 0.3125rem 0 0.3125rem 0 !important;
				padding: 0 0.3125rem 0 0.3125rem !important;
				background: white !important;
				color: black !important;
			}
		}
	}
}
