@import 'src/shared/styles/mixins';
@import 'src/shared/styles/variables';
.tile {
	//BOX SHADOW
	box-shadow: 0 1px 1px 0 map-get($basic-zi-colors, 'accent-dark'),
		0 1px 1px 0 map-get($basic-zi-colors, 'accent-dark');

	@include theme-aware('color', 'text-primary');
	@include theme-aware('background', 'card-background');
	height: 11.5rem;

	display: flex; /* Use flexbox for flexible layouts */
	flex-direction: column; /* Ensure content stacks vertically */
	align-items: stretch; /* Ensure child elements stretch to fill available width */
	height: auto; /* Allow height to adjust dynamically based on content */
	min-height: 0; /* Reset any potential fixed minimum height */
	box-sizing: border-box; /* Include padding and border in element's height */
	justify-content: space-between;
	padding: 1rem;

	.tile-content {
		display: flex;
		flex-direction: row;
		gap: $space-md;
		flex-grow: 1; /* Allow content to grow as needed */

		height: 100%;
		.tile-column {
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			.column-content {
				display: flex;
				flex-direction: column;
				justify-content: flex-start;

				span:not(.title-header) {
					font-size: $text-sm;
				}

				.tile-header {
					font-size: $text-lg;
					font-weight: bold;
					height: 40px;
				}

				.sub-text {
					font-weight: bold;
				}
			}

			.svg-inline--fa {
				min-height: $font-icon-size;
				min-width: $font-icon-size;
				margin-top: $space-sm;
			}
		}
	}

	.tile-footer {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		gap: $space-md;
		font-size: $text-sm;
		.spacer {
			width: $text-lg;
		}
		.footer-content {
			display: flex;
			flex-direction: row;
			width: 100%;
			display: flex; /* Use flexbox for alignment */
			justify-content: flex-end; /* Align content to the right */
			align-items: center; /* Ensure vertical alignment, if needed */

			.pf-c-menu-toggle {
				margin: 0;
				padding: 0;
				.svg-inline--fa {
					@include theme-aware('color', 'text-primary');
					min-height: $font-icon-size;
					min-width: $font-icon-size;
					margin: 0;
					padding: 0;
				}
			}
		}
	}

	.alert-value {
		position: relative;
		right: 40px;
		width: 175px;
		height: 44px;
		display: block;
	}

	.push-down {
		position: relative;
		top: 13px;
		right: 40px;
	}

	.alert-val {
		display: inline;
		white-space: nowrap;
	}
}

.bordered {
	border-radius: 0.375rem;
	border-top-width: 0.225rem;
	border-top-style: solid;
	border-right: 0.0375rem solid whitesmoke;
	border-left: 0.0375rem solid whitesmoke;
	border-bottom: 0.0375rem solid whitesmoke;
}

.down-trend {
	@include theme-aware('border-top-color', 'trend-down');
	.tile-content {
		.svg-inline--fa {
			@include theme-aware('color', 'trend-down');
		}
	}
}

.up-trend {
	@include theme-aware('border-top-color', 'trend-up');
	.tile-content {
		.svg-inline--fa {
			@include theme-aware('color', 'trend-up');
		}
	}
}

.no-change {
	@include theme-aware('border-top-color', 'trend-neutral');
	.tile-content {
		.svg-inline--fa {
			@include theme-aware('color', 'trend-neutral');
		}
	}
}

.just-info {
	border-top-style: dotted;
	@include theme-aware('color', 'text-primary');
	.tile-content {
		.svg-inline--fa {
			@include theme-aware('color', 'text-primary');
			color: black;
		}
	}
}

.alert-tile-container {
	.tile-content {
		color: black;
	}

	.footer-content {
		color: black;
	}
}

.alert-tile-container {
	&:not(.no-alerts) {
		display: grid;
		grid-template-columns: repeat(6, 14rem); /* Repeat 6 alert tiles per row */
		row-gap: 1.375rem;
		column-gap: 6rem;
	}
}

@media (max-width: 132rem) {
	.alert-tile-container {
		&:not(.no-alerts) {
			display: grid;
			grid-template-columns: repeat(6, 0.2fr); /* Repeat 4 alert tiles per row */
			column-gap: 1.375rem;
		}
	}
}

@media (max-width: 100rem) {
	.alert-tile-container {
		&:not(.no-alerts) {
			display: grid;
			grid-template-columns: repeat(5, 0.2fr); /* Repeat 3 alert tiles per row */
			column-gap: 1.375rem;
		}
	}
}

@media (max-width: 48rem) {
	.alert-tile-container {
		&:not(.no-alerts) {
			display: block; /* Change to a different layout method or value as needed */
			grid-template-columns: none; /* Remove grid columns */
			gap: initial; /* Reset gap property */
			overflow: initial; /* Reset overflow property */
		}
	}

	.tile {
		margin-bottom: 3.125rem;
	}
}
