@import 'src/shared/styles/mixins';
@import 'src/shared/styles/variables';

.dnd-container {
	aspect-ratio: 5/3;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 1px 4px 0 rgba(0, 0, 0, 0.19);

	.delete-icon,
	.minus-icon,
	.plus-icon {
		display: none;
	}
	.box {
		position: absolute;
		background-color: 'white';
		padding: 0.1rem 0.1rem;
		cursor: move;

		&:hover {
			border: 1px dashed gray;
			border-radius: 0.3rem;
			.delete-icon,
			.minus-icon,
			.plus-icon {
				display: flex;
				cursor: pointer;
			}
		}
	}
}

.presentation-page-preview {
	position: relative;
	aspect-ratio: 5/3;
	border-width: 0.15rem;
	border-style: solid;
	border-color: transparent;
	border-radius: 0.375rem;
	margin-bottom: 0.8rem;
	@include theme-aware('background-color', 'background-shade-content');
	cursor: pointer;
	box-shadow: 0 1px 1px 0 map-get($basic-zi-colors, 'accent-dark'),
		0 1px 1px 0 map-get($basic-zi-colors, 'accent-dark');
	.delete-icon {
		display: none;
	}

	&:hover {
		box-shadow: 0 1px 2px 0 map-get($basic-zi-colors, 'primary'),
			0 2px 5px 0 map-get($basic-zi-colors, 'primary');

		.delete-icon {
			display: flex;
			cursor: pointer;
		}
	}

	&.selected {
		@include theme-aware('border-color', 'brand-primary');
		@include theme-aware('background-color', 'background-shade-side');
		cursor: auto;
		box-shadow: 0 1px 1px 0 map-get($basic-zi-colors, 'primary'),
			0 1px 2px 0 map-get($basic-zi-colors, 'primary');
	}

	&.new {
		@include theme-aware('background-color', 'background-shade-content');
		@include theme-aware('border-color', 'background-shade-side');
	}
}

.present-details-container {
	.row-container {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding-bottom: 1.5rem;

		.start-container {
			display: flex;
			flex-direction: row;
			gap: 1.5rem;
			min-width: 40%;
			align-items: flex-end;

			.folder-container {
				align-items: center;
				display: flex;
				flex-direction: row;
				gap: 0.5rem;
			}
		}
	}
}
