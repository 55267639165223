@import 'src/shared/styles/mixins';
@import 'src/shared/styles/variables';

.component-container {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	gap: $space-md;

	.create-selection-options-list {
		display: flex;
		flex-direction: row;
		justify-content: space-evenly;
		align-items: center;
		margin-top: $space-sm;
		flex-wrap: wrap;

		.create-selection-option {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;

			.create-selection-option-icon {
				width: 8rem;
				height: 8rem;
				border: 1px solid black;
				@include theme-aware('border-color', 'card-border');
				border-radius: 0.5rem;

				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;

				@include standard-icon;
			}
		}
	}
}
