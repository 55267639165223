@import 'src/shared/styles/variables';
@import 'src/shared/styles/mixins';
@import '../../shared/styles/colors';

.data-builder-container {
	&.h-100 {
		height: 100%;
	}

	.no-data-container {
		height: 100%;
		border-style: dashed;
		border-width: 0.125rem;
		@include theme-aware('border-color', 'card-border');
		@include theme-aware('background', 'card-background');

		.no-data-headers {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			height: 100%;
			@include theme-aware('color', 'text-primary');
			small {
				@include theme-aware('color', 'text-primary');
			}
		}
	}

	.row-container {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding-bottom: $space-lg;

		.start-container {
			display: flex;
			flex-direction: row;
			gap: $space-lg;
			min-width: 15%;
			justify-content: center;
			align-items: flex-end;
			flex-basis: 50%;

			.folder-container {
				&.pf-c-form__group {
					.pf-c-form__group-control {
						width: 100%;
					}
					.pf-c-form__group-label {
						padding: 0;
						@include standard-icon;
					}
				}

				.pf-c-select {
					margin-bottom: 0;
				}

				display: flex;
				flex-direction: row;
				align-items: center;
				gap: $space-sm;
			}
		}

		.end-container {
			display: flex;
			flex-direction: row;
			gap: $space-md;
		}
	}
}

.drill-header,
.insights-header {
	display: flex;
	align-items: center;
	position: relative;
	right: 2.25rem;

	h1 {
		padding-left: 0.5rem;
	}

	svg {
		padding-left: 0.625rem;
	}
}

.dv-drill,
.dv-insights {
	margin-left: 2.813rem;
	margin-right: 2.813rem;
	margin-bottom: 1.25rem;

	.btn-graph-summary {
		margin: auto;
		display: block;
	}
}

@keyframes slide-out {
	0% {
		transform: translateX(100%);
	}
	100% {
		transform: translateX(0%);
	}
}

.right-sidebar-panel {
	z-index: 9999999;
	background: #d2d2d2;
	border: solid 1px darkgray;
	animation: slide-out 0.4s ease-in-out;
}

.pf-c-sidebar__main {
	background: #d2d2d2 !important;
}

.nested-fields {
	&:first-child {
		padding-top: 0.313rem;
	}

	padding-top: 0.125rem;
	padding-left: 0.625rem;
}

.drill-items-container {
	.data-builder-drop-grid-container {
		position: inherit;
	}
}

.close-drill,
.close-insights {
	cursor: pointer;
	position: relative;
	bottom: 0.938rem;
	left: 2rem;
}

.btn-add-question {
	bottom: 0.625rem;
	min-width: 2.5rem !important;
}

.pr-10 {
	padding-right: 0.625rem;
}

.pointer {
	cursor: pointer;
}
