.dimension-value-modal {
	.actions {
		display: flex;
		justify-content: space-between;
		padding-bottom: 1rem;

		input {
			flex-basis: 30%;
		}
	}
}
