@import 'src/shared/styles/variables';
@import 'src/shared/styles/mixins';
@import '../../shared/styles/colors';

.lambda-insights-container {
	margin-left: 2.813rem;
	margin-right: 2.813rem;
	margin-bottom: 1.25rem;

	&.insights-background {
		background-color: map-get($zi-brand-colors, 'bright-gray');
		margin: unset;
		padding: 1rem;
		margin-right: 2rem;
		overflow-y: auto;
		width: 20rem;
		max-height: 28rem;
	}

	.insights,
	.actions {
		padding-bottom: 2rem;

		.header {
			padding-bottom: 1rem;
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		.insight-item {
			overflow-wrap: break-word;
		}

		.pf-c-button.pf-m-primary {
			min-width: unset;
		}
	}
}
