@import 'src/shared/styles/mixins';
@import 'src/shared/styles/variables';

.pf-c-page {
	.pf-c-masthead {
		@include theme-aware('background', 'brand-primary');
		display: inline-block;
		padding-right: 0rem;

		.pf-c-brand {
			width: 10rem;
		}

		.pf-c-masthead__content {
			@include theme-aware('background', 'background-shade-content');
			display: inline-grid;
			padding-right: 3rem;
			font-size: 1rem;
		}

		a {
			@include theme-aware('color', 'text-primary');
			padding: 0.5rem 1rem;
		}

		.pf-c-dropdown__menu {
			@include theme-aware('background', 'background-shade-content');
			min-width: 12rem !important;
			padding-top: 0px !important;
			padding-bottom: 0px !important;
		}

		.pf-c-dropdown__toggle-image,
		.pf-c-dropdown__toggle-image svg {
			font-weight: normal;
		}

		.pf-c-dropdown__menu-item {
			font-size: 1rem;

			&:hover,
			&:focus,
			&:active {
				@include theme-aware('background', 'background-shade-content');
				@include theme-aware('color', 'brand-primary');
			}

			&.active {
				@include theme-aware('color', 'brand-primary');
				font-weight: bold;
			}
		}
		#avatar-dropdown {
			padding: 0rem;
		}
	}
}

.menu-item {
	font-weight: normal;
	margin-right: 0px !important;

	.pf-c-tile {
		// @include theme-aware('color', 'text-primary');
		padding: 1.5rem 2rem;
		background-color: transparent;
		font-weight: bold;

		.pf-c-tile__body {
			font-size: 1rem;
			@include theme-aware('color', 'text-primary');
		}

		&:active,
		&.pf-m-selected {
			--pf-c-tile--TranslateY: -0.09rem;
			.pf-c-tile__body p {
				@include theme-aware('color', 'brand-primary');
				font-weight: bold;
			}
		}

		&:hover {
			.pf-c-tile__body p {
				@include theme-aware('color', 'brand-primary');
			}
		}

		&::before {
			border-color: transparent !important;
		}

		&:focus::after,
		&::after {
			// @include theme-aware('background-color', 'brand-primary');
			background-color: transparent;
		}

		// &.pf-m-selected::after {
		// 	@include theme-aware('background-color', 'brand-primary');
		// }

		&:hover::after {
			background-color: transparent;
		}
	}

	.pf-c-dropdown {
		--pf-c-dropdown__toggle--m-plain--child--LineHeight: unset;
	}
}

.pf-c-icon__content {
	width: 2rem;
	@include theme-aware('color', 'text-primary');

	&:hover {
		@include theme-aware('color', 'text-primary-hover');
	}
}

img {
	max-height: 2rem;
}

.zin-header-logo {
	max-height: 100%;
	width: 200px !important;
}

.zin-login-logo {
	max-height: 70px !important;
}

.bz-pill {
	position: relative;
	bottom: 5px;
	font-weight: bold;
	@include theme-aware('background-color', 'brand-primary');

	&:hover {
		cursor: pointer;
	}
}
