.preview-table-container {
	margin-top: 2rem;
	width: 100%;

	.preview-title {
		font-size: 1.2em;
		color: rgb(51, 51, 51);
		font-weight: bold;
		fill: rgb(51, 51, 51);
		text-align: center;
		word-wrap: break-word;
		padding: 0 1rem 0 1rem;
	}

	&.widget-height {
		margin-top: 1rem;
		height: calc(100% - 1.5rem);
	}

	.cursor {
		cursor: pointer;
	}

	.scroll-container {
		max-height: 26.0625rem;
		overflow-y: auto;
		width: 100%;
		overflow-x: auto;
	}

	.pf-c-pagination {
		display: none;
	}

	table {
		border-collapse: separate;
		border-spacing: 0;

		.sticky-header {
			position: sticky;
			top: 0;
			background: white;
			z-index: 1;
		}

		th {
			border-bottom: 1px solid var(--pf-global--BorderColor--100);

			&.pivot-header {
				.pf-c-table__sort-indicator {
					transform: rotate(90deg);
				}
			}
		}

		td {
			border-bottom: 1px solid var(--pf-global--BorderColor--100);
		}
	}
}

.table-hyperlink {
	color: var(--pf-c-table__sort__button__text--Color) !important;
}

th.pf-m-center {
	text-align: -webkit-center !important;
}
