@import 'shared/styles/bootstrapper';

body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow-x: hidden;
}

#root {
	overflow-x: hidden;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

:root {
	--pf-global--BorderRadius--sm: 6px;
	--pf-global--FontFamily--sans-serif: 'Inter', 'RedHatText', 'Overpass', overpass, helvetica,
		arial, sans-serif;
}

/*.pf-c-alert-group.pf-m-toast {*/
/*	position: fixed;*/
/*	bottom: var(--pf-c-alert-group--m-toast--Top);*/
/*	left: var(--pf-c-alert-group--m-toast--Right);*/
/*	z-index: var(--pf-c-alert-group--m-toast--ZIndex);*/
/*	width: calc(100% - var(--pf-c-alert-group--m-toast--Right) * 2);*/
/*	max-width: var(--pf-c-alert-group--m-toast--MaxWidth);*/
/*	top: inherit;*/
/*}*/

.pull-right {
	float: right;
	// margin-right: 1.25rem;
}

.ptb-10 {
	margin: 1rem 0 1rem 0 !important;
}

.btn-login {
	width: 100%;
}

.spinner-container {
	display: block;
	text-align: center;
}

.child-tabs {
	padding-top: 1 rem;
}

.pf-m-danger {
	color: red;
}

.pf-c-tabs__item.pf-m-current > button {
	font-weight: bold;
}
