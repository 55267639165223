@import 'src/shared/styles/variables';

.cardList {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-content: flex-start;
	padding: $space-md;
	flex-wrap: wrap;
	width: 100%;
	height: 100%;
}

.scrollable-cards .ziCard {
	width: $zi-card-width; /* Set a fixed width for your cards */
	flex: 0 0 auto;
}
