@import 'src/shared/styles/variables';
@import 'src/shared/styles/mixins';

.ziCard {
	//BOX SHADOW
	box-shadow: 0 1px 1px 0 map-get($basic-zi-colors, 'accent-dark'),
		0 1px 1px 0 map-get($basic-zi-colors, 'accent-dark');

	font-size: $text-lg;
	height: 11.5rem;
	width: $zi-card-width;

	margin-right: $space-sm;
	margin-bottom: $space-sm;
	flex: 0 0 auto;

	display: flex;
	flex-direction: column;
	justify-content: space-between;

	padding: $space-md;

	@include theme-aware('background', 'card-background');
	@include theme-aware('color', 'text-primary');

	@include theme-aware('border-color', 'card-border');
	border-width: 0.06rem;
	border-style: solid;
	border-radius: $border-radius-all;

	span:not(.fa-stack) {
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.shared-by-sub-text {
		font-size: 12px;
	}

	.share-icon {
		margin: unset;
		top: 20px;
		left: -4px;
		background: #f5f5f5;
		border-radius: 10px;
		height: 1rem;
		width: 1rem;
	}

	.footer {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-end;
		height: 2rem;
		.svg-inline--fa {
			min-height: $font-icon-size;
			min-width: $font-icon-size;
		}

		.pf-c-badge {
			@include theme-aware('background', 'badge');
			@include theme-aware('color', 'badge-text');
		}
	}

	&:hover {
		text-decoration: none;
		@include theme-aware('background', 'card-hover');
	}

	.chart-pin {
		cursor: pointer;
	}
}
