.grid-layout-widget {
	.pf-c-input-group::after {
		background-color: white;
		z-index: -1;
		content: '';
		position: fixed;
		top: 0;
		left: 0;
		width: calc(100% + 30rem);
		height: calc(100% + 10rem);
	}
}
