@import 'src/shared/styles/variables';
@import 'src/shared/styles/mixins';

.pf-c-input-group {
	> button {
		&.pf-c-button,
		&.pf-c-button,
		&.pf-c-button {
			border-bottom: 0.063rem solid #8a8d90;
			border-top: 0.063rem solid #f0f0f0;
			border-right: 0.063rem solid #f0f0f0;
			border-left: none;
			padding-top: 0.25rem;

			border-top-right-radius: 0.375rem;
			border-bottom-right-radius: 0.375rem;

			&::after {
				border: none;
			}
		}

		&.pf-c-button:disabled,
		&.pf-c-button.pf-m-disabled,
		&.pf-c-button.pf-m-aria-disabled {
			background-color: transparent;
		}
	}
}

.pf-c-text-input-group__text {
	&::before,
	&::after {
		border-right: none;
		border-top-left-radius: 0.375rem;
		border-bottom-left-radius: 0.375rem;
	}
}

// .pf-c-button.pf-m-control::after {
// 	border: none;
// }

.pf-c-form__group-control {
	@include theme-aware('color', 'text-primary');
}

.quickfilter-label {
	padding-left: 1rem;
	padding-right: 1rem;
	margin-right: 0.5rem;
	a {
		font-size: $text-smm;
	}
}

.chart-data {
	display: flex;
	gap: 1rem;

	.chart-section {
		flex-basis: 85%;
	}

	.insights-section {
		flex-basis: 15%;

		.generate-insights-btn {
			margin-right: 1rem;
		}
	}
}
