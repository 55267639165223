.chart-container {
	height: 100%;
	display: block;

	.preview-table-container {
		.cursor {
			cursor: pointer;
		}
	}
}

.zi-chart-container {
	display: block;
	width: 100%;
	min-width: 100%;
}

.chart-container {
	.zi-chart-container {
		padding: 0 0.5rem 0 0.5rem;
	}
}

.zi-chart-container,
.chart-type-container {
	.pf-m-primary,
	.btn-dropdown-container > .pf-c-dropdown__toggle {
		min-width: initial;
	}
}
