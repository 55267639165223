@import '../../shared/styles/colors';

.user-secure-filters-icon-container {
	h6 {
		border-bottom: 1px solid;
	}
	.user-secure-filters-table-container {
		height: 250px;

		.pf-c-scroll-outer-wrapper {
			min-height: unset;
		}
	}
	.caption-text {
		padding: unset;
		color: map-get($basic-zi-colors, 'dark');
	}
	tbody {
		tr:not(.logic-group-text) {
			background-color: map-get($zi-brand-colors, 'bright-gray');
		}
	}
}
